export default function fetchWithTimeout(
  resource,
  options = { timeout: 5000 }
) {
  const { timeout } = options;
  
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = fetch(resource, {
    ...options,
    signal: controller.signal,
  });

  return Promise.race([
    response,
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error("timeout")), timeout)
    ),
  ]).finally(() => clearTimeout(id)); // Clears the timeout when the fetch is done
}
