import "../App.css";
import ChatFooter from "./Footer";
import Header from "./Header";

export default function Layout({ header, children }) {
  return (
    <div className="App">
      {header ? header : <Header />}

      <div
        style={{ display: "flex", flexDirection: "column", flex: "1 1 85%" }}
      >
        <main>{children}</main>

        <ChatFooter />
      </div>
    </div>
  );
}
