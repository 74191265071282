import IconWizardHat from "./icons/IconWizardHat";
import { useLocation, Link, NavLink } from "react-router-dom";
import { useIsAuthenticated } from "react-auth-kit";
import { Fragment } from "react";

export default function Header({ children }) {
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();

  return (
    <header>
      <div className="sidebar-nav-container">
        <div
          style={{
            textAlign: "left",
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <a target="_blank" href="https://makerendgame.com" rel="noreferrer">
            <img
              src="/header.png"
              style={{
                filter: "invert(100%)",
                width: "200px",
                marginBottom: "-1px",
              }}
              alt="Logo"
            />
          </a>
          <div style={{ position: "relative" }}>
            <p className="muted" style={{ fontSize: "1.125rem" }}>
              Endgame Wizard
            </p>
            <IconWizardHat
              style={{
                position: "absolute",
                top: "-8px",
                left: "75px",
                rotate: "-19deg",
              }}
            />
          </div>
        </div>
        {location.pathname === "/logs" ? (
          <h4 className="muted" style={{ padding: "0 1rem" }}>
            Logs
          </h4>
        ) : null}
        {children ? children : null}
      </div>

      <div
        style={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {/* {isAuthenticated() ? <PredictorSettings /> : null} */}
        {location.pathname !== "/" ? <Link to="/">Back to chat</Link> : null}
        {isAuthenticated() ? (
          <Fragment>
            <NavLink to="/logs">Logs</NavLink>
            <NavLink to="/context-settings/indices">Context settings</NavLink>
          </Fragment>
        ) : null}
        <a target="_blank" href="https://forum.makerdao.com/" rel="noreferrer">
          Join the Discussion
        </a>
      </div>
    </header>
  );
}
