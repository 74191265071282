import { Fragment } from "react";
import NewIndexForm from "../components/index-form/NewIndexForm";
import { useOutletContext } from "react-router-dom";

export default function CreateIndexPage() {
  const refetch = useOutletContext();

  return (
    <Fragment>
      <h4 className="muted" style={{ padding: "0 1rem" }}>
        Create new Index
      </h4>
      <NewIndexForm refetch={refetch} />
    </Fragment>
  );
}
