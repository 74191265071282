import ChatPage from "./pages/ChatPage";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import { AuthProvider, RequireAuth } from "react-auth-kit";
import LogsPage from "./pages/LogsPage";
import ContextSettingsPage from "./pages/ContextSettingsPage";
import IndexDetailsPage from "./pages/IndexDetailsPage";
import CreateIndexPage from "./pages/CreateIndexPage";

function App() {
  return (
    <AuthProvider authType={"localstorage"} authName={"_auth"}>
      <Router>
        <Routes>
          <Route path="/" element={<ChatPage />} />

          <Route
            path="/logs"
            element={
              <RequireAuth loginPath="/login">
                <LogsPage />
              </RequireAuth>
            }
          />

          <Route
            element={
              <RequireAuth loginPath="/login">
                <ContextSettingsPage>
                  {(refetch) => <Outlet context={refetch} />}
                </ContextSettingsPage>
              </RequireAuth>
            }
          >
            <Route
              path="/context-settings/indices/:indexId"
              element={<IndexDetailsPage />}
            />

            <Route
              path="/context-settings/indices"
              element={<CreateIndexPage />}
            />
          </Route>

          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
