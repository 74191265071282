import ReactMarkdown from "react-markdown";
import { useLocation } from "react-router-dom";
import remarkGfm from "remark-gfm";

export default function ChatBody({ isFirstRequest, answers, questions }) {
  const location = useLocation();

  return (
    <div className="chat-body">
      <div className="scrollable">
        {!isFirstRequest && (
          <div>
            {questions.map((question, index) => {
              return (
                <div className="questions-answers" key={question + index}>
                  <p style={{ color: "#7E7E7E" }}>
                    {location.pathname === "/logs" ? (
                      <b>Somebody</b>
                    ) : (
                      <b>You</b>
                    )}
                  </p>
                  <p>{question}</p>
                  <p style={{ color: "#1DC1AE", marginTop: 30 }}>
                    <b>Wizard</b>
                  </p>
                  {answers[index] ? (
                    <div>
                      <pre style={{ marginBottom: 30 }}>
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                          {answers[index]}
                        </ReactMarkdown>
                      </pre>
                    </div>
                  ) : (
                    <div>
                      <p className="loading-dots">
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        )}
      </div>
    </div>
  );
}
