import { Fragment, useState } from "react";
import { useEffect } from "react";
import { useAuthHeader } from "react-auth-kit";
import EditIndexForm from "../components/index-form/EditIndexForm";
import { useOutletContext, useParams } from "react-router-dom";

export default function IndexDetailsPage() {
  const refetch = useOutletContext();
  const [index, setIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const authHeader = useAuthHeader();
  const { indexId } = useParams();

  useEffect(() => {
    async function fetchIndexDetails() {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/index/${indexId}`,
          {
            method: "GET",
            headers: {
              Authorization: authHeader(),
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setIndex(data);
      } catch (error) {
        console.error(error);
      }

      setLoading(false);
    }
    fetchIndexDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexId]);

  if (index && !loading) {
    return (
      <Fragment>
        <h4 className="muted" style={{ padding: "1rem" }}>
          {index.category}
        </h4>
        <EditIndexForm initialValues={index} refetch={refetch} />
      </Fragment>
    );
  }

  return (
    <h4 className="muted" style={{ padding: "1rem" }}>
      Loading...
    </h4>
  );
}
