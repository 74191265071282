import React, { useState, useCallback } from "react";
import "./index-form.css";
import { useNavigate } from "react-router-dom";
import { useAuthHeader } from "react-auth-kit";

export default function IndexForm({ refetch }) {
  const navigate = useNavigate();
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const authHeader = useAuthHeader();

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const resetForm = useCallback(() => {
    setCategory("");
    setTags("");
    setFiles([]);
    setError(null);
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (category !== "" && files.length > 0) {
        const formData = new FormData();
        formData.append("category", category);
        formData.append("tags", tags);
        for (let file of files) {
          formData.append("files", file);
        }
  
        const response = await fetch(`${process.env.REACT_APP_API_URL}/index`, {
          headers: {
            Authorization: authHeader(),
          },
          method: "POST",
          body: formData,
        });
  
        if (response.ok) {
          console.log("Index created successfully");
          resetForm();
          navigate("/context-settings/indices");
          refetch();
  
          return;
        }
  
        if (response.status === 409) {
          setError(
            "Category already exists, please use another name or edit the existing category"
          );
  
          return;
        }
  
        console.log("Failed to create index");
        setError(response.error())

        return;
      }

      setError('Category and files are mandatory fields')
    },
    [authHeader, category, files, navigate, refetch, resetForm, tags]
  );

  return (
    <form onSubmit={handleSubmit} className="index-form">
      <label>
        Category:
        <input
          type="text"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />
      </label>

      <label>
        Tags:
        <textarea
          type="text"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
        />
      </label>

      <label>
        Files:
        <input type="file" multiple onChange={handleFileChange} />
      </label>

      <input type="submit" value="Submit" />

      {error ? <p>{error}</p> : null}
    </form>
  );
}
