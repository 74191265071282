import ChatBody from "../components/ChatBody";
import Layout from "../components/Layout";
import Header from "../components/Header";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useAuthHeader } from "react-auth-kit";
import IconTrash from "../components/icons/IconTrash";

export default function LogsPage() {
  const [logs, setLogs] = useState([]);
  const [selectedLog, setSelectedLog] = useState(null);
  const [loading, setLoading] = useState(false);
  const authHeader = useAuthHeader();

  const questions = useMemo(() => selectedLog?.questions ?? [], [selectedLog]);
  const answers = useMemo(() => selectedLog?.answers ?? [], [selectedLog]);

  useEffect(() => {
    async function fetchLogs() {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/logs`, {
          headers: {
            Authorization: authHeader(),
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setLogs(data);
      } catch (error) {
        console.error(error);
      }

      setLoading(false);
    }
    fetchLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteConversation = useCallback(
    async (sessionId) => {
      const confirmed = window.confirm(
        "Are you sure you want to delete this log?"
      );

      if (confirmed) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/log/${sessionId}`,
            {
              method: "DELETE",
              headers: {
                Authorization: authHeader(),
              },
            }
          );

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          setLogs((prevLogs) =>
            prevLogs.filter((log) => log.sessionId !== sessionId)
          );
          if (selectedLog && sessionId === selectedLog.sessionId) {
            setSelectedLog(null);
          }
          console.log(`Conversation ${sessionId} deleted successfully.`);
        } catch (error) {
          console.error("Error deleting conversation:", error);
        }
      }
    },
    [authHeader, selectedLog]
  );

  return (
    <Layout
      header={
        <Header>
          {loading ? (
            <p style={{ padding: "1rem" }}>Loading...</p>
          ) : (
            <ul style={{ maxHeight: "65vh", overflow: "scroll" }}>
              {logs.map((log) => {
                return (
                  <li key={log.timestamp}>
                    <div
                      className="log-list-item"
                      onClick={() => {
                        setSelectedLog(log);
                      }}
                      style={{ padding: "calc(1rem + 4px) 1rem 1rem 1rem" }}
                    >
                      {new Date(log.timestamp).toLocaleString()}
                      <div
                        className="log-list-remove-item"
                        onClick={() => deleteConversation(log.sessionId)}
                      >
                        <IconTrash width={24} height={24} />
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </Header>
      }
    >
      <main>
        {selectedLog ? (
          <h4 className="muted" style={{ padding: "1rem" }}>
            {new Date(selectedLog.timestamp).toLocaleString()}
          </h4>
        ) : (
          <h4
            className="muted"
            style={{
              textAlign: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            Talk to the wizard if you want to see logs here.
          </h4>
        )}

        {selectedLog ? (
          <ChatBody
            questions={questions}
            answers={answers}
            isFirstRequest={questions.length === 0 && answers.length === 0}
          />
        ) : null}
      </main>
    </Layout>
  );
}
