import ChatBody from "../components/ChatBody";
import Layout from "../components/Layout";
import { useEffect, useRef } from "react";
import { useState, useCallback } from "react";
import IconSend from "../components/icons/IconSend";
import { v4 as uuid } from "uuid";
import fetchWithTimeout from "../utils/fetchWithTimeout";

export default function ChatPage() {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [modelVersion, setModelVersion] = useState("gpt_35_turbo");

  const [isFirstRequest, setIsFirstRequest] = useState(true);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const chatSession = useRef(null);
  const initialRender = useRef(true);

  // const apiUsage = useCallback(async () => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/api-usage`,
  //       {
  //         method: "GET",
  //       }
  //     );
  //     if (!response.ok) {
  //       throw new Error("Failed to clear chat");
  //     }
  //     const data = await response.json();

  //     console.log(data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, []);

  const fetchData = useCallback(
    (question) => {
      fetchWithTimeout(
        `${process.env.REACT_APP_API_URL}/${modelVersion}/ask?sessionId=${
          chatSession.current
        }&query=${encodeURIComponent(question.substring(0, 4000))}`,
        { timeout: 1000 * 60 * 10 }
      )
        .then((response) => response.json())
        .then((data) => {
          setAnswers((prevAnswers) => [...prevAnswers, data.response]);
          setIsLoading(false);
        })
        .catch(() => {
          setAnswers((prevAnswers) => [
            ...prevAnswers,
            "Wizard did not respond. Try regenerate response in a minute.",
          ]);
          setIsLoading(false);
        });
      setInput(() => "");
    },
    [modelVersion]
  );

  const reGenerateResponse = useCallback(() => {
    const lastQuestion = questions[questions.length - 1];
    setAnswers((prevAnswers) => [...prevAnswers.slice(0, -1)]);
    setIsLoading(true);

    fetchData(lastQuestion);
  }, [fetchData, questions, setAnswers, setIsLoading]);

  const generateResponse = useCallback(
    (question) => {
      if (isLoading) {
        return;
      }

      setIsFirstRequest(false);
      setIsLoading(true);
      setQuestions((prevQuestions) => [...prevQuestions, question]);

      fetchData(question);
    },
    [fetchData, isLoading, setIsFirstRequest, setIsLoading, setQuestions]
  );

  const scrollToBottom = () => {
    const div = document.querySelector(".scrollable");
    div.scrollTop = div.scrollHeight - div.clientHeight;
  };

  const clear = useCallback(async (newSession) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/clear_chat?session_id=${chatSession.current}`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to clear chat");
      }
      const data = await response.text();
      console.log(data);
    } catch (error) {
      console.error(error);
    }

    setAnswers([]);
    setQuestions([]);
    chatSession.current = newSession ? uuid() : null;
    setIsFirstRequest(true);
  }, []);

  useEffect(() => {
    chatSession.current = uuid();

    if (initialRender.current === false) {
      return () => {
        clear();
      };
    }

    initialRender.current = false;
    console.log(`Chat seesion ${chatSession.current} started.`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [questions, answers]);

  return (
    <Layout>
      <select
        className="model-selector"
        disabled={questions.length > 0}
        value={modelVersion}
        onChange={(e) => setModelVersion(e.target.value)}
      >
        <option value="gpt_35_turbo">GPT 3.5 Turbo</option>
        <option value="gpt_4">GPT 4 (Smarter but slower)</option>
      </select>

      <ChatBody
        questions={questions}
        answers={answers}
        isFirstRequest={isFirstRequest}
      />

      <div className="chat-footer">
        <div className="buttons">
          <button
            disabled={isLoading || answers.length === 0}
            onClick={reGenerateResponse}
          >
            Regenerate Response
          </button>
          <button
            style={{ marginLeft: 20 }}
            disabled={isLoading || answers.length === 0}
            onClick={() => clear(true)}
          >
            Clear All
          </button>
        </div>
        <div className="input-wrapper">
          <input
            type="text"
            placeholder="Ask here"
            value={input}
            autoFocus
            onFocus={(e) => (e.target.placeholder = "")}
            onBlur={(e) => (e.target.placeholder = "Ask here")}
            onChange={(e) => {
              setInput(e.target.value.substring(0, 4000));
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                generateResponse(input);
              }
            }}
          />
          <IconSend
            className="send"
            onClick={() => {
              generateResponse(input);
            }}
          />
        </div>
      </div>
    </Layout>
  );
}
