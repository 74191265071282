import Layout from "../components/Layout";
import Header from "../components/Header";
import { Fragment, useState, useCallback } from "react";
import { useEffect } from "react";
import { useAuthHeader } from "react-auth-kit";
import IconTrash from "../components/icons/IconTrash";
import { NavLink, useNavigate } from "react-router-dom";

export default function ContextSettingsPage({ children }) {
  const [indices, setIndices] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [contextIsBuilding, setContextIsBuilding] = useState(false);
  const authHeader = useAuthHeader();
  const navigate = useNavigate();

  const fetchIndices = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/indices`, {
        headers: {
          Authorization: authHeader(),
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setIndices(data);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  }, [authHeader]);

  useEffect(() => {
    fetchIndices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildContext = useCallback(
    async (e) => {
      e.preventDefault();
      const confirmed = window.confirm(
        "This action will take several minutes and it will overwrite all existing indices and files based on the new context settings. Please confirm if you are sure to proceed."
      );

      if (confirmed) {
        setContextIsBuilding(true);
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/build-context`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: authHeader(),
              },
            }
          );

          if (!response.ok) {
            setContextIsBuilding(false);
            console.error("Error building the context:", response);
            window.alert(
              "Something went wrong creating the context. Please contact support. Error was logged in the console."
            );

            return;
          }

          setContextIsBuilding(false);
          window.alert("Context was created successfully");
        } catch (error) {
          setContextIsBuilding(false);
          console.error("Error building the context:", error);
          window.alert(
            "Something went wrong creating the context. Please contact support. Error was logged in the console."
          );
        }
      }
    },
    [authHeader]
  );

  const deleteIndex = useCallback(
    async (indexId) => {
      const confirmed = window.confirm(
        "Are you sure you want to delete this index?"
      );

      if (confirmed) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/index/${indexId}`,
            {
              method: "DELETE",
              headers: {
                Authorization: authHeader(),
              },
            }
          );

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          setIndices((prevIndices) =>
            prevIndices.filter((index) => index._id !== indexId)
          );
          if (selectedIndex && indexId === selectedIndex._id) {
            setSelectedIndex(null);
          }
          console.log(`Index ${indexId} deleted successfully.`);
          navigate("/context-settings/indices");
        } catch (error) {
          console.error("Error deleting index:", error);
        }
      }
    },
    [authHeader, navigate, selectedIndex]
  );

  return (
    <Layout
      header={
        <Header>
          <Fragment>
            <h4 className="muted" style={{ padding: "0 1rem" }}>
              Indices
            </h4>
            {loading ? (
              <p style={{ padding: "1rem" }}>Loading...</p>
            ) : (
              <div className="indices-nav">
                <ul style={{ maxHeight: "65vh", overflow: "scroll" }}>
                  {indices.map((index) => {
                    return (
                      <li key={index.category_id}>
                        <NavLink
                          className="log-list-item"
                          to={`/context-settings/indices/${index._id}`}
                          style={{ padding: "calc(1rem + 4px) 1rem 1rem 1rem" }}
                        >
                          {index.category}
                          <div
                            className="log-list-remove-item"
                            onClick={() => deleteIndex(index._id)}
                          >
                            <IconTrash width={24} height={24} />
                          </div>
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>

                <div style={{ display: "flex", justifyContent: 'center', gap: '1rem' }}>
                  <button onClick={() => navigate("/context-settings/indices")}>
                    Add Index
                  </button>
                  <button className="button-important" disabled={contextIsBuilding} onClick={buildContext}>
                    {contextIsBuilding
                      ? "Building context..."
                      : "Build context"}
                  </button>
                </div>
              </div>
            )}
          </Fragment>
        </Header>
      }
    >
      {children(fetchIndices)}
    </Layout>
  );
}
