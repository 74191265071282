import { useState } from "react";
import { useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  const signIn = useSignIn();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_API_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (
          signIn({
            token: data.token,
            expiresIn: Number(data.expiresIn),
            tokenType: "jwt",
            authState: data.authUserState,
          })
        ) {
          navigate("/logs");
        } else {
          navigate("/login");
        }
      })
      .catch((error) => console.error("Error: ", error));
  };

  return (
    <div
      style={{
        backgroundColor: "#0F0F0F",
        color: "#F1F1F1",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src="/header.png"
        alt="Logo"
        style={{
          width: "200px",
          marginBottom: "2rem",
          filter: "invert(100%)",
        }}
      />
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "2rem",
          borderRadius: "10px",
          backgroundColor: "#222222",
        }}
      >
        <label style={{ marginBottom: "1rem" }}>
          Username:
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={{
              marginLeft: "1rem",
              padding: "0.5rem",
              borderRadius: "5px",
              border: "none",
            }}
          />
        </label>
        <label style={{ marginBottom: "1rem" }}>
          Password:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{
              marginLeft: "1rem",
              padding: "0.5rem",
              borderRadius: "5px",
              border: "none",
            }}
          />
        </label>
        <button
          type="submit"
          style={{
            backgroundColor: "#F1F1F1",
            color: "#0F0F0F",
            padding: "0.5rem 1rem",
            borderRadius: "5px",
            border: "none",
          }}
        >
          Log in
        </button>
      </form>
    </div>
  );
}
