export default function ChatFooter() {
  return (
    <footer>
      <span>
        Please be aware that the answers provided by this chatbot are generated
        using an AI language model based on the GPT architecture. The responses
        may not always be accurate or up-to-date. We recommend verifying the
        information provided by the chatbot with the official project
        documentation or consulting an expert in the field if you are unsure
        about any aspect of the project. By using this chatbot, you acknowledge
        that the developers are not responsible for any inaccuracies, errors, or
        omissions in the answers provided, nor for any consequences resulting
        from the use of the information obtained through this chatbot.
      </span>
      <span>
        Brought to you by{" "}
        <a href="http://daocraft.cx" target="_blank" rel="noreferrer">
          DAOCRAFT
        </a>
      </span>
    </footer>
  );
}
