import React, { useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import "./index-form.css";

export default function EditIndexForm({ initialValues, refetch }) {
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [newFiles, setNewFiles] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);
  const [removedExistingFiles, setRemovedExistingFiles] = useState([])
  const [error, setError] = useState(null);
  const authHeader = useAuthHeader();

  useEffect(() => {
    if (initialValues) {
      setCategory(initialValues.category);
      setTags(initialValues.tags);
      setExistingFiles(initialValues.files);
    }
  }, [initialValues]);

  const handleFileChange = (event) => {
    setNewFiles((prevState) => [...prevState, ...event.target.files]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (category !== "" && (newFiles.length > 0 || existingFiles.length > 0)) {
      const formData = new FormData();
      formData.append("category", category);
      formData.append("tags", tags);
      for (let file of newFiles) {
        formData.append("files", file);
      }
      for (let file of removedExistingFiles) {
        formData.append("removedFiles", file);
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/index/${initialValues._id}`,
        {
          headers: {
            Authorization: authHeader(),
          },
          method: "PUT",
          body: formData,
        }
      );

      if (response.ok) {
        console.log("Index updated successfully");
        refetch();

        return;
      }

      if (response.status === 409) {
        setError(
          "Category already exists, please use another name or edit the existing category"
        );

        return;
      }

      console.log("Failed to update the index");
      setError(response.error());

      return;
    }

    setError("Category and files are mandatory fields");
  };

  const handleRemoveFile = async (fileName) => {
    if (typeof fileName === "string") {
      setRemovedExistingFiles((prevState) => [...prevState, fileName])
      setExistingFiles((prevState) => prevState.filter((file) => file !== fileName))

      return;
    }
    
    setNewFiles((prevState) =>
      prevState.filter((file) => file.name !== fileName.name)
    );
  };

  return (
    <form onSubmit={handleSubmit} className="index-form">
      <label>
        Category:
        <input
          type="text"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />
      </label>

      <label>
        Tags:
        <textarea
          type="text"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
        />
      </label>

      <label>
        Files:
        <input type="file" multiple onChange={handleFileChange} />
      </label>

      {newFiles.length > 0 || existingFiles.length > 0 ? (
        <div>
          <h4>Uploaded files:</h4>
          <ul>
            {existingFiles.map((filePath, index) => (
              <li key={`existing-${index}`}>
                {getFileName(filePath)}
                <button
                  type="button"
                  onClick={() => handleRemoveFile(filePath)}
                >
                  Remove
                </button>
              </li>
            ))}
            {Array.from(newFiles).map((file, index) => (
              <li key={`new-${index}`}>
                {file.name}{" "}
                <button type="button" onClick={() => handleRemoveFile(file)}>
                  Remove
                </button>
              </li>
            ))} 
          </ul>
        </div>
      ) : null}

      <input type="submit" value="Save changes" />

      {error ? <p>{error}</p> : null}
    </form>
  );
}

function getFileName(file) {
  if (typeof file === "string") {
    const pathArray = file.split("/");
    const fileName = pathArray[pathArray.length - 1];

    return fileName;
  }

  return file.name;
}
