import React from "react";

export default function IconWizardHat(props) {
  const defaultProps = {
    width: 20,
    height: 20,
  };

  props = { ...defaultProps, ...props };

  return (
    <svg
      viewBox="0 0 48 48"
      fill={props.fill || "#1AAB9B"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24 40C35.0457 40 44 38.2091 44 36C44 34.5611 40.7012 33.2997 35 32.5949L27 12L15 8L18 14L13 32.5949C7.2988 33.2997 4 34.5611 4 36C4 38.2091 12.9543 40 24 40Z"
        stroke={props.stroke || "#7E7E7E"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
